import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useIsFetching } from '@tanstack/react-query'
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar'
import Footer from '../../examples/Footer'
import MDBox from 'components/MDBox'
import CommandBar from 'components/CommandBar/CommandBar.component'
import Loader from '../Loader'
import ErrorFallback from 'components/ErrorFallback/ErrorFallback.component'
import PageTitle from './PageTitle'
import NoticesContainer from '../notices/NoticesContainer'

export default function Page({
    title,
    notices,
    status,
    commands,
    className,
    color,
    children,
    busy,
    ...rest
}) {
    const isFetching = useIsFetching()

    return (
        <ErrorBoundary
            FallbackComponent={ErrorFallback}
        >
            <MDBox
                display="flex"
                flexDirection="column"
                pb={2}
                sx={{
                    height: '100vh',
                }}
            >
                <DashboardNavbar>
                    <MDBox
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        gap="1.6em"
                    >
                        <PageTitle title={title} status={status} />
                        <Loader visible={isFetching || busy} size="1.2em" />
                    </MDBox>
                    {commands && <CommandBar commands={commands} color={color} />}
                </DashboardNavbar>
                <MDBox
                    component="main"
                    className={`page ${className}`}
                    flexGrow={1}
                    {...rest}
                    sx={{
                        overflow: 'scroll',
                    }}
                >
                    <NoticesContainer notices={notices} />
                    {children}
                </MDBox>
                <Footer />
            </MDBox>
        </ErrorBoundary>
    )
}
