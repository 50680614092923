import { createContext, useContext, useState } from 'react'
import moment from 'moment'
import { useLocation } from 'react-router-dom'

const NoticesContext = createContext([])

export const useNoticesContext = () => {
    return useContext(NoticesContext)
}

export const NoticesProvider = ({ children }) => {
    const [last, setLast] = useState(0)
    const [notices, setNotices] = useState([])

    const location = useLocation()

    const addNotices = ({
        notices: noticesItemOrArray,
        clear,
    }) => {
        const notices = Array.isArray(noticesItemOrArray) ? noticesItemOrArray : [noticesItemOrArray]

        const now = moment()

        let index = last

        const newNotices =
            notices.map((notice) => ({
                type: 'info',
                ...notice,
                id: ++index,
                route: location.pathname,
                time: now.format('HH:mm:ss'),
            }))
        setLast(index)
        setNotices((previousNotices) =>
            clear
                ? [...newNotices]
                : [...previousNotices, ...newNotices],
        )
    }

    const removeNotices = (idsItemOrArray) => {
        const ids = Array.isArray(idsItemOrArray) ? idsItemOrArray : [idsItemOrArray]

        setNotices((previousNotices) =>
            previousNotices.filter((notice) => !ids.includes(notice.id)),
        )
    }

    const clearNotices = () => {
        setNotices([])
    }

    return (
        <NoticesContext.Provider value={{ notices, addNotices, removeNotices, clearNotices }}>
            {children}
        </NoticesContext.Provider>
    )
}
