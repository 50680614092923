import MDSnackbar from 'components/MDSnackbar'
import MDBox from 'components/MDBox'
import { useNotificationContext } from './NotificationContext'

const Notification = () => {
    const {
        notifications,
        removeNotification,
    } = useNotificationContext()

    return (
        <MDBox>
            <div className="notifications">
                {notifications.map((notification) => {

                    const contentSections = [
                        {
                            property: 'content',
                            render: (value) => value,
                        },
                        {
                            property: 'code',
                            render: (value) => `קוד: ${value}`,
                        },
                    ]

                    const content = <>
                        {contentSections.map(
                            ({ property, render }) => {
                                const value = notification[property]
                                return value == null
                                    ? null
                                    : <p>{render(value)}</p>
                            },
                        )}
                    </>

                    return (
                        <MDBox key={notification.id}>
                            <MDSnackbar
                                color={notification.color}
                                icon={notification.icon}
                                title={notification.title}
                                content={content}
                                dateTime={notification.time}
                                open={true}
                                close={() => removeNotification(notification.id)}
                                duration={notification.duration}
                                bgWhite={false}
                            />
                        </MDBox>
                    )
                })}
            </div>
        </MDBox>
    )
}

export default Notification
