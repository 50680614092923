import React, { useContext, useEffect, useState } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import AuthContext from 'units/auth/AuthContext'
import useQueryLibrary from 'hooks/useQueryLibrary'
import MDBox from 'components/MDBox'
import Page from '../pages/Page'
import FetchContext from '../../context/fetch/FetchContext'
import DocumentCard from 'components/document-card/documentCard'
// import DocumentPopup from '../../components/document-popup/DocumentPopup'
import voucherCardArray from '../../data-organizers/vouchers/voucherCardArray'
// import voucherPopupArray from '../../data-organizers/vouchers/voucherPopupArray'
import checkCardArray from '../../data-organizers/checks/checkCardArray'
// import checkPopupArray from '../../data-organizers/checks/checkPopupArray'
import Loader from '../Loader'
import MDBadge from 'components/MDBadge'
import './entry-page-existing-documents.scss'
import { getDocumentKind } from '../documents/documents.utils'

function EntryPageExistingDocuments({ createDocument, busy }) {
    const { token } = useContext(AuthContext)

    // const [popup, setPopup] = useState(null)
    const [isAnyDocuments, setIsAnyDocuments] = useState(null)
    const [paymentDocument, setPaymentDocument] = useState(null)
    // const [isPopupLoading, setIsPopupLoading] = useState(false)
    // const [entry, setEntry] = useState({})
    const [isEntryDisabled, setIsEntryDisabled] = useState(false)
    const [voucherDocuments, setVoucherDocuments] = useState([])

    const { companyId, entryId, documentId } = useParams()

    const queryLibrary = useQueryLibrary()

    const fetcher = useContext(FetchContext)

    const {
        data: entry,
        isSuccess,
    } = useQuery({
        queryKey: queryLibrary.oneEntryForEntryPageQuery(entryId),
        queryFn: async () => {
            const data = await fetcher.fetchEntryForEntryPage(entryId)
            return data
        },
    })

    useEffect(() => {
        if (isSuccess) {
            const documents = entry.documents ? entry.documents : []

            setPaymentDocument(documents.find(document => getDocumentKind(document) === 'payment'))
            setVoucherDocuments(documents.filter(document => getDocumentKind(document) === 'voucher'))

            const hasDocuments = paymentDocument != null && voucherDocuments.length !== 0
            setIsAnyDocuments(Boolean(hasDocuments))

            if (entry.status === 'Compiled' || entry.status === 'Sent') {
                setIsEntryDisabled(true)
            }
        }
    }, [isSuccess, entry])

    // const getDocuments = async () => {
    //   // const entryData = await fetchOneEntry(entryId)
    //   // const documents = entryData.documents ?
    //   //   entryData.documents :
    //   //   []
    //   // setPaymentDocument(documents.find(document => document.type === 'check'))
    //   // setVoucherDocuments(documents.filter(document =>
    //   //   document.type === 'voucher42'
    //   //   || document.type === 'voucher27'))
    //   // if (![null, undefined].includes(paymentDocument) && voucherDocuments.length !== 0) {
    //   //   setIsAnyDocuments(true)
    //   // }
    //   // else {
    //   //   setIsAnyDocuments(false)
    //   // }
    //   // setEntry(entryData)
    //   // if (entryData.status === 'Compiled' || entryData.status === 'Sent') {
    //   //   setIsEntryDisabled(true)
    //   // }
    // }

    // const queryClient = useQueryClient()

    // const deleteDocumentMutation = useMutation({
    //   mutationFn: (documentId) => {
    //     console.log('deleting')
    //     setBusy(true)
    //     return fetch({
    //       method: 'delete',
    //       path: `/documents/${documentId}`,
    //     })
    //   },
    //   onSuccess: () => {
    //     queryClient.invalidateQueries({
    //         queryKey: ['entries', entryId],
//         })
    //   },
    //   // onSuccess: (data, variables, context) => {
    //   //   console.log('success', data, variables, context)

    //   //   const entryId = data

    //   //   navigate(`/companies/${companyId}/entries/${entryId}`)
    //   // },
    //   onSettled: (data) => {
    //     console.log('mutation settled')

    //     setBusy(false)
    //   }
    // })

    // useEffect(() => {
    //   getDocuments()
    //   // note: do not return the result of getDocuments because it's a promise and will crash the app
    // }, [])

    const renderPayment = (document) => {
        const frontFile = document.images.find(image => image.type === 'Check_Front_jpg')

        const image = frontFile?.file != null
            ? `${process.env.REACT_APP_STRAPIURL_BASE}${frontFile.file.url}?jwt=${token}`
            : ``

        const checkInfo = checkCardArray(document)
        const schedules = {
            'immediate': 'תשלום שוטף',
            'deferred': 'תשלום דחוי ל-15 לחודש',
        }
        const info = Object.assign(
            {},
            {
                'סכום': document.currency,
                'מזהה מסמך': document.id,
                'תזמון': document.schedule in schedules ? schedules[document.schedule] : 'לא מוגדר',
            },
            ...checkInfo,
        )

        // let warning
        // switch (document.status) {
        //   case 'missing_details':
        //     warning = 'חסרים פרטים'
        //     break
        //   case 'invalid':
        //     warning = 'המסמך לא תקין'
        //     break
        // }

        return (
            <DocumentCard
                key={document.id}
                image={image}
                title={`שיק ${document.id}`}
                info={info}
                status={document.status}
                // warning={warning}
                route={`documents/${document.id}`}
                // className="check-card"
                mode="card"
                // onDelete={(e) => {
                //   deleteDocumentWithConfirmation(paymentDocument.id)
                //   deleteDocument(paymentDocument.id)}
                // }
                xs={2}
                disabled={busy}
            />
        )
    }

    const NoDocuments = ({ message }) => {
        return (
            <MDBadge
                variant="gradient"
                color="warning"
                style={{ marginBlockEnd: 4 }}
                badgeContent={message}
            />
        )
    }

    const renderPayments = () => {
        return (
            <>
                <h2>אמצעי תשלום</h2>
                <div className="entry-page-existing-documents_vouchers-grid">
                    {
                        paymentDocument
                            ? renderPayment(paymentDocument)
                            : <NoDocuments message="טרם נקלט אמצעי תשלום" />
                    }
                </div>
            </>
        )
    }

    function getVoucherTypeLabel(type) {
        if (type === 'voucher42') {
            return 'ברקוד (42)'
        }
        if (type === 'voucher38') {
            return 'ברקוד (38)'
        }
        else if (type === 'voucher27') {
            return 'מגנטי'
        }
        else {
            return 'טרם נסרק'
        }
    }

    const renderVoucher = (document) => {
        const frontFile = document.images.find(image => image.type === 'Voucher_Front_jpg')

        const image = frontFile != null
            ? `${process.env.REACT_APP_STRAPIURL_BASE}${frontFile.file.url}?jwt=${token}`
            : ``

        const voucher = document[document.type]
        const voucherInfo = voucher ? voucherCardArray(voucher) : []
        const info = Object.assign(
            {},
            {
                'סכום': document.currency || 'אין מידע',
                'מזהה מסמך': document.id,
                'סוג': getVoucherTypeLabel(document.type),
            },
            ...voucherInfo,
        )
        //Voucher27's images aren't good for Document cards.

        // const hasVoucher = voucherDoc.voucher42 || voucherDoc.voucher27

        // const { status } = voucherDoc

        let warning
        // switch (status) {
        //   case 'missing_details':
        //     warning = 'חסרים פרטים'
        //     break
        //   case 'invalid':
        //     warning = 'המסמך לא תקין'
        //     break
        // }
        // if (!hasVoucher) {
        //   warning = 'שובר ריק - לחצו לעריכה'
        // }

        // if (voucherDoc.type === 'voucher27' && !voucherFileSrc) {
        //   warning = 'לא הועלו מסמכים'
        // }

        return (
            <DocumentCard
                key={document.id}
                image={image}
                title={`שובר ${document.id}`}
                info={info}
                status={document.status}
                warning={warning}
                route={`documents/${document.id}`}
                // className={`${hasVoucher ? '' : 'empty-document-card' }`}
                mode="card"
                // onDelete={(e) => {
                //   deleteDocumentWithConfirmation(voucherDocument.id)
                //   deleteDocument(voucherDocument.id)}
                // }
                disabled={busy}
            />
        )
        // : (
        //   <DocumentCard
        //     // key={index}
        //     keyID={voucherDoc.id}
        //     image={voucherFileSrc}
        //     title="שובר"
        //     warning="שובר ריק - לחצו לעריכה"
        //     info={info}
        //     onClick={() => setPopup(voucherDoc)}
        //     className="document-card voucher-card empty-document-card"
        //     key={index}
        //   />
        // )
    }

    const renderVouchers = () => {
        return (
            <>
                <h2>שוברים</h2>
                <MDBox
                    // className="entry-page-existing-documents_vouchers-grid"

                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '1rem',
                        flexWrap: 'wrap',
                    }}
                >
                    {
                        voucherDocuments.length
                            ? (
                                voucherDocuments.map((voucherDoc, index) => {
                                    return renderVoucher(voucherDoc, index)
                                })
                            )
                            : <NoDocuments message="אין שוברים לתשלום" />
                    }
                </MDBox>
            </>
        )
    }

    // const renderPopup = () => {
    //   let detailsArr, title
    //   if (popup.type.toLowerCase().includes('check') && popup.check) {
    //     const { detailsArrCol1, detailsArrCol2 } = checkPopupArray(popup.check)
    //     title = `שיק מספר  ${popup.id}`
    //     detailsArr = [detailsArrCol2, [`סכום: ${popup.currency || 'אין מידע'}`].concat(detailsArrCol1)]
    //   }
    //   else if (popup.type.toLowerCase().includes('voucher') && (popup[popup.type] !== null || popup[popup.type] !== null)) {
    //     const { voucherType, detailsArrCol1, detailsArrCol2 } = voucherPopupArray((popup.voucher42 !== null) ? popup.voucher42 : popup.voucher27)
    //     title = `${voucherType}  ${popup.id}`
    //     detailsArr = [detailsArrCol1, [`סכום: ${popup.currency || 'אין מידע'}`].concat(detailsArrCol2)]
    //   }
    //   else {
    //     detailsArr = [['לא נמצאו פרטים, יש לערוך את המסמך']]
    //   }

    //   return (
    //     <Modal isOpen={popup} onRequestClose={() => setPopup(null)}>
    //       {isPopupLoading ? <Loader /> : <DocumentPopup
    //         imageSrcArr={popup.images?.map(image => {
    //           return {
    //             src: `${process.env.REACT_APP_STRAPIURL_BASE}${image.file?.url}?jwt=${token}`,
    //             legend: image.type,
    //           }
    //         })}
    //         title={title}
    //         detailsArr={detailsArr}
    //         buttonsArr={[
    //           { buttonText: 'מחיקה', disabled: isEntryDisabled, onClick: async () => deleteDocument(popup.id) },
    //           { buttonText: 'עריכה', disabled: isEntryDisabled, onClick: () => navigate(`/companies/${companyId}/entries/${entryId}/EditDocument/${popup.id}`) },
    //         ]}
    //         closeFunc={() => setPopup(null)}
    //       />}
    //     </Modal>
    //   )
    // }

    // const [ActionButtons] = useState([
    //   {
    //     buttonText: 'שובר חדש מסוג מגנטי',
    //     onClick: () => createDocument('voucher27'),
    //     type: 'voucher27',
    //   },
    //   {
    //     buttonText: 'שובר חדש מסוג ברקוד',
    //     onClick: () => createDocument('voucher42'),
    //     type: 'voucher42',
    //   },
    //   {
    //     buttonText: 'שיק חדש',
    //     onClick: () => createDocument('check'),
    //     type: 'check',
    //   },
    // ])

    const hasNonMagneticVoucherDocuments = voucherDocuments.some((voucherDocument) => voucherDocument.editor !== 'magnetic_voucher')
    const hasNonBarcodeVoucherDocuments = voucherDocuments.some((voucherDocument) => voucherDocument.editor !== 'barcode_voucher')

    const commands = [
        {
            label: 'שיק חדש',
            onClick: () => createDocument('check', 'payment', 'magnetic_check'),
            type: 'check',
            disabled: busy || isEntryDisabled || paymentDocument != null, // || (paymentDocument && button.type === 'check') || isEntryDisabled
        },
        {
            label: 'שובר חדש מסוג מגנטי',
            // todo: remove type from createDocument call
            onClick: () => createDocument('voucher27', 'voucher', 'magnetic_voucher'),
            type: 'voucher27',
            disabled: busy || isEntryDisabled || hasNonMagneticVoucherDocuments, // || (paymentDocument && button.type === 'check') || isEntryDisabled
        },
        {
            label: 'שובר חדש מסוג ברקוד',
            onClick: () => createDocument('voucher42', 'voucher', 'barcode_voucher'),
            type: 'voucher42',
            disabled: busy || isEntryDisabled || hasNonBarcodeVoucherDocuments, // || (paymentDocument && button.type === 'check') || isEntryDisabled
        },
        {
            scheme: 'link',
            icon: 'arrow_upward',
            to: `/companies/${companyId}/entries`,
            position: 'end',
            color: 'info',
            disabled: busy,
        },
    ]
    //   ActionButtons.map((button, index) => {
    //   // const disabled =
    //   //   (paymentDocument && button.type === 'check')
    //   //   || isEntryDisabled
    //   // if (paymentDocument && button.type === 'check') {
    //   return {
    //     // disabled,
    //     onClick: button.onClick,
    //     label: button.buttonText,
    //   }
    //   // return <Button disabled key={index} onClick={button.onClick}>{button.buttonText}</Button>
    //   // }
    //   // return {
    //   //   disabled: isEntryDisabled,
    //   //   onClick: button.onClick,
    //   //   label: button.buttonText,
    //   // }
    //   // return <Button disabled={isEntryDisabled} key={index} onClick={button.onClick}>{button.buttonText}</Button>
    // })


    const page = (
        <Page
            commands={commands}
            title={`רשומה ${entry?.id}`}
            status={entry?.status || 'New'}
        >
            {/* <h3>{`רשומה ${entry.id}`}</h3> */}
            {/* <EntryStatusBadge status={entry.status} /> */}
            {/* <h5>{entry.status}</h5> */}
            {/* <div className="page-header">
        <div className="ActionButtons">
          {ActionButtons.map((button, index) => {
            if (checkDocument && button.type === 'check') {
              return <Button disabled key={index} onClick={button.onClick}>{button.buttonText}</Button>
            }
            return <Button disabled={isEntryDisabled} key={index} onClick={button.onClick}>{button.buttonText}</Button>
          })}
        </div>
      </div> */}
            {renderPayments()}
            {renderVouchers()}
            {/* <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={3}>
              <ReportsLineChart
                color="dark"
                title="completed tasks"
                description="Last Campaign Performance"
                date="just updated"
                chart={tasks}
              />
            </MDBox>
          </Grid> */}

            {/* {popup && renderPopup()} */}
        </Page>
    )

    if (documentId) {
        return <Outlet />
    }
    else if ([false, true].includes(isAnyDocuments)) {
        return page
    }
    else {
        return <Loader minHeight="50vh" mx={2} />
    }
}

export default EntryPageExistingDocuments
