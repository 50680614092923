export const messages = {
    DOCUMENT_DOES_NOT_EXIST: `המסמך לא נמצא`,
    DOCUMENT_TYPE_MISMATCH: `סוג המסמך לא תואם את הסוג הצפוי`,
    INVALID_BANK_ACCOUNT: `פרטי החשבון שגויים`,
    MISSING_IMAGES: `חסרות תמונות`,
    MISSING_BANK: `יש להזין מס׳ בנק`,
    MISSING_BRANCH: `יש להזין מס׳ סניף`,
    MISSING_ACCOUNT: `יש להזין מס׳ חשבון`,
    INVALID_BANK: `מס׳ הבנק לא תקין`,
    INVALID_CHECKSUM: `ספרת הביקורת שגויה`,
    INVALID_ACCOUNT_TOO_SHORT: `מס׳ החשבון קצר מדי`,
    INVALID_ACCOUNT_TOO_LONG: `מס׳ החשבון ארוך מדי`,
    BANK_IS_NOT_A_NUMBER: `מס׳ החשבון אינו מספר`,
    UNKNOWN_BANK: `מספר הבנק לא מוכר`,
    INVALID_VOUCHER: `השובר לא תקין`,
}

export default function Message({ code }) {
    if (code == null) {
        return null
    }

    if (!messages.hasOwnProperty(code)) {
        return code
    }

    return messages[code]
}
