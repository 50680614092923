import { Card, CardContent, CardHeader, Grid, IconButton } from '@mui/material'
import { Close } from '@mui/icons-material'

function Section({
    title,
    bgColor = 'transparent',
    children,
    close,
    ...props
}) {
    return (
        <Grid
            item
            {...props}
        >
            <Card
                sx={{
                    background: bgColor,
                }}
            >
                <CardHeader
                    title={title}
                    action={
                        close == null
                            ? null
                            : (
                                <IconButton onClick={close}>
                                    <Close />
                                </IconButton>
                            )
                    }
                />
                <CardContent>
                    {children}
                </CardContent>
            </Card>
        </Grid>
    )
}

export default Section
