import { Stack } from '@mui/material'
import FormField from 'components/forms/FormField'
import Section from 'components/containers/Section'

function AppFormSection({
    title,
    section,
    fields,
    children,
    disabled,
    ...props
}) {
    return (
        <Section
            title={title}
            {...props}
        >
            {fields && (
                <Stack>
                    {Object.entries(fields).map(([key, field], index) => {
                        return field.meta?.section === section ? (
                            <FormField
                                key={index}
                                label={field.label || `שדה ${index}`}
                                type={field.type}
                                name={key}
                                disabled={disabled}
                                options={field.meta?.options}
                                autoFocus={field.meta?.autoFocus && !disabled}
                                autoComplete={field.meta?.autoComplete}
                                className={{
                                    ltr: field.meta?.forceLtr === true,
                                    right: field.meta?.forceRight === true,
                                }}
                            />
                        ) : null
                    })}
                </Stack>
            )}
            {children}
        </Section>
    )
}

export default AppFormSection
