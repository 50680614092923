import { groupBy } from 'ramda'
import React from 'react'
import { Stack, useTheme } from '@mui/material'
import NoticesSection from './NoticesSection'
import { useNoticesContext } from './NoticesContext'
import { useLocation } from 'react-router-dom'

export default function NoticesContainer() {
    const { notices } = useNoticesContext()

    if (notices == null) {
        return null
    }

    const location = useLocation()

    const routeNotices = notices.filter((notice) => notice.route === location.pathname)

    const groupedNotices = groupBy((notice) => notice.type, routeNotices)

    const options = [
        {
            type: 'error',
            title: 'שגיאות',
        },
        {
            type: 'warning',
            title: 'אזהרות',
        },
        {
            type: 'info',
            title: 'הערות',
        },
    ]

    const theme = useTheme()

    return (
        <Stack
            direction="column"
            gap={1}
        >
            {
                options.map((sectionOptions) => {
                    const { type, ...sectionProps } = sectionOptions
                    const colors = theme.palette.badgeColors[type]
                    return (
                        <NoticesSection
                            item
                            key={type}
                            notices={groupedNotices[type]}
                            bgColor={colors.background}
                            color={colors.text}
                            {...sectionProps}
                        />
                    )
                })
            }
        </Stack>
    )
}
