import React from 'react'
import { ListItemIcon } from '@mui/material'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import { Error } from '@mui/icons-material'
import Section from '../containers/Section'
import Notice from './Notice'
import { useNoticesContext } from './NoticesContext'

export default function NoticesSection({
    title,
    bgColor,
    color,
    notices,
}) {
    if (notices == null) {
        return null
    }

    const { removeNotices } = useNoticesContext()

    const closeSection = () => {
        const ids = notices.map(n => n.id)
        removeNotices(ids)
    }

    return (
        <Section
            title={title}
            bgColor={bgColor}
            color={color}
            close={() => closeSection()}
        >
            <List direction="column" spacing={2}>
                {
                    notices.map((notice, index) => (
                        <ListItem
                            key={index}
                        >
                            <ListItemIcon>
                                <Error />
                            </ListItemIcon>
                            <Notice
                                key={index}
                                notice={notice}
                            />
                        </ListItem>
                    ))
                }
            </List>
        </Section>
    )
}
