import pxToRem from 'assets/theme/functions/pxToRem'

const cardHeader = {
    styleOverrides: {
        root: {
            paddingTop: pxToRem(8),
            paddingBottom: 0,
        },
    },
}

export default cardHeader
