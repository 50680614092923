import React from 'react'
import Typography from '@mui/material/Typography'
import Message from '../Message'

export default function Notice({ notice }) {
    if (notice == null) {
        return null
    }

    return (
        <Typography variant="body1">
            <Message code={notice.code} />
        </Typography>
    )
}
