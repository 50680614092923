import React, { useContext } from 'react'
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import HybridRouter from './components/HybridRouter/HybridRouter.component'

// // react-router components
// import {
//   Routes,
//   Route,
//   Navigate,
//   useLocation,
// } from 'react-router-dom'
// @mui material components
// import { Icon, Button } from '@mui/material'
// Material Dashboard 2 PRO React components
// import MDBox from 'components/MDBox'
// // Material Dashboard 2 PRO React examples
// import Sidenav from 'examples/Sidenav'
// import Configurator from 'examples/Configurator'
// import routes from './routes'
import { MaterialUIControllerProvider } from 'context/material-ui'

import { ConfirmProvider } from 'material-ui-confirm'

import { NotificationProvider } from 'units/notifications/NotificationContext'
import Notification from 'units/notifications/Notification'
import AuthContext from './units/auth/AuthContext'
import Themer from './components/theme/Themer'

// import MDButton from 'components/MDButton'

// // Images
// import brandWhite from 'assets/images/logo.png'
// import brandDark from 'assets/images/logo.png'

// import SignIn from './pages/auth/sign-in/sign-in.component'
// import './App.css'
// import AuthenticatedLayout from './components/AuthenticatedLayout/AuthenticatedLayout.component'
// import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
// import DashboardNavbar from 'examples/Navbars/DashboardNavbar'

// import Breadcrumbs from 'examples/Breadcrumbs'

function App({
    // setStrapiURL,
    platform,
}) {
    const authenticator = useContext(AuthContext)

    const queryClient = new QueryClient({
        queryCache: new QueryCache({
            onError: (error) => {
                console.error('query client error', error)
                if (error.response?.status === 401) {
                    authenticator?.logOut()
                }
            },
        }),
    })

    // const [onMouseEnter, setOnMouseEnter] = useState(false)

    // const { pathname } = useLocation()

    // // Open sidenav when mouse enter on mini sidenav
    // const handleOnMouseEnter = () => {
    //   if (miniSidenav && !onMouseEnter) {
    //     setMiniSidenav(dispatch, false)
    //     setOnMouseEnter(true)
    //   }
    // }

    // // Close sidenav when mouse leave mini sidenav
    // const handleOnMouseLeave = () => {
    //   if (onMouseEnter) {
    //     setMiniSidenav(dispatch, true)
    //     setOnMouseEnter(false)
    //   }
    // }

    // // Change the openConfigurator state
    // const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator)

    // // Setting the dir attribute for the body element
    // useEffect(() => {
    //   document.body.setAttribute('dir', direction)
    // }, [direction])

    // // Setting page scroll to 0 when changing the route
    // useEffect(() => {
    //   document.documentElement.scrollTop = 0
    //   document.scrollingElement.scrollTop = 0
    // }, [pathname])

    // const getRoutes = (routes) => {
    //   return routes.map((route) => {
    //     console.log('route', route)
    //     if (route.collapse) {
    //       return getRoutes(route.collapse)
    //     }

    //     if (route.route) {
    //       const Component = route.component
    //       return (
    //         <Route
    //           exact
    //           path={route.route}
    //           key={route.key}
    //           element={
    //             <DashboardLayout>
    //               {/* <Breadcrumbs /> */}
    //               <DashboardNavbar />
    //               {/* <Component /> */}
    //               <div>route in layout</div>
    //             </DashboardLayout>
    //           }
    //         />
    //       )
    //     }

    //     return null
    //   })

    //   {routes.map(({ path, Component, editMode }, key) => (
    //     <Route
    //       exact
    //       path={path}
    //       key={key}
    //       render={routeProps => (
    //         <AuthenticatedLayout
    //           setStrapiURL={setStrapiURL}
    //           fullName={fullName}
    //         >
    //           <Component
    //             {...routeProps}
    //             token={getAuthenticationToken()}
    //             editMode={editMode}
    //           />
    //         </AuthenticatedLayout>
    //       )}
    //     />
    //   ))}
    // }

    // function AuthenticateUser(successRedirect, routeProps, failureRedirect) {
    //   if (checkIfAuthed()) {
    //     if (typeof successRedirect == 'string') {
    //       return routeProps.history.push(successRedirect)
    //     }
    //     else if (typeof successRedirect == 'object') {
    //       return successRedirect
    //     }
    //     else {
    //       routeProps.history.push('/')
    //     }
    //   }
    //   else {
    //     if (typeof failureRedirect == 'string') {
    //       return routeProps.history.push(failureRedirect)
    //     }
    //     else if (typeof failureRedirect == 'object') {
    //       return failureRedirect
    //     }
    //     else {
    //       routeProps.history.push('/')
    //     }
    //   }
    // }

    const defaultConfirmOptions = {
        title: 'נא לאשר את הפעולה',
        confirmationText: 'אישור',
        cancellationText: 'ביטול',
        confirmationButtonProps: {
            color: 'primary',
            // variant: 'contained',
            autoFocus: true,
        },
        cancellationButtonProps: {
            color: 'secondary',
            // variant: 'contained',
        },
        dialogActionsProps: {
            disableSpacing: false,
            color: 'primary',
            sx: {
                gap: 1,
            },
        },
    }

    return (
        <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={false} />
            <MaterialUIControllerProvider>
                <Themer>
                    <NotificationProvider>
                        <ConfirmProvider defaultOptions={defaultConfirmOptions}>
                            <HybridRouter type={platform.router}>
                                {/* <>
                                    <Sidenav
                                      color={sidenavColor}
                                      brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
                                      brandName="VIPost"
                                      routes={routes}
                                      onMouseEnter={handleOnMouseEnter}
                                      onMouseLeave={handleOnMouseLeave}
                                    />
                                    <Configurator />
                                    {configsButton}
                                  </> */}
                                {/* <Routes>
                                    {getRoutes(routes)}
                                    <Route path="*" element={<Navigate to='/companies' />} />
                                  </Routes> */}
                            </HybridRouter>
                            <Notification />
                        </ConfirmProvider>
                    </NotificationProvider>
                </Themer>
            </MaterialUIControllerProvider>
        </QueryClientProvider>
    )
}

export default App
